@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1380px !important;
  }
}

.capitalize {
  text-transform: lowercase !important;
  -webkit-text-transform: lowercase !important;
}

.capitalize::first-line {
  text-transform: capitalize !important;
  -webkit-text-transform: capitalize !important;
}

/* Fix Webkit ::first-line issues */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .capitalize::first-line {
    font-variant: small-caps;
  }
  .capitalize::first-letter {
  }
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*:focus {
  outline: 0 !important;
}

html,
body,
#root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialased;
}

body,
input,
button {
  font:
    1rem 'Roboto',
    sans-serif;
}

button {
  cursor: pointer;
}

.Toastify__toast--success {
  background-color: #4a76b9;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--error {
  background-color: #4a76b9;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--info {
  background-color: #4a76b9;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--warning {
  background-color: #4a76b9;
  border-radius: 4px;
  text-align: center;
}

.f-16 {
  font-size: 16px;
}

.f-14 {
  font-size: 14px;
}

.bg-black {
  background-color: black !important;
}

.f-black {
  color: black;
}

.f-white {
  color: white;
}

.f-blue {
  color: #4a76b9;
}

.btn-primario {
  margin: 5px 0 0;
  height: 44px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-primario:hover {
  background-color: #5b87ca;
}

.btn-vermelho {
  margin: 5px 0 0;
  height: 44px;
  background-color: #dc3545;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-vermelho:hover {
  background-color: #c82333;
}

.btn-secundario {
  margin: 5px 0 0;
  height: 44px;
  background-color: black;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.btn-secundario:hover {
  background-color: black;
  color: white;
}

.f-10 {
  font-size: 10px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-white {
  color: white !important;
}

.f-green {
  color: green;
}

.f-red {
  color: red;
}

.hr-loja {
  width: 100%;
  border-top: 3px solid #4a76b9;
}

.hr-curso {
  width: 100%;
  border-top: 3px solid #a5a5a5;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, '.') '. ';
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, '.') ' ';
}

.row:before,
.row:after {
  display: none !important;
}

.caret-4-desc:after {
  content: '\2193';
}
.caret-4-desc:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 0.4;
}

.caret-4-asc:after {
  content: '\2193';
  opacity: 0.4;
}
.caret-4-asc:before {
  margin-left: 3.5px;
  content: '\2191';
}

ul.pagination.react-bootstrap-table-page-btns-ul {
  margin-top: 1rem;
}
.btn-whats {
  margin: 5px 0 0;
  height: 44px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  padding: 12px;
  cursor: pointer;
  text-decoration: none !important;
}
.btn-limpo {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

div.ril-toolbar.ril__toolbar {
  background-color: unset;
}

.table-dark td {
  border-color: #ebeced !important;
  background-color: white;
  color: black;
}

.table-dark tfoot {
  background-color: white;
  color: black;
}

.table-dark thead,
.table-dark th {
  border-color: #4a76b9 !important;
  border-bottom: none;
}

.table-dark {
  background-color: #4a76b9 !important;
  white-space: nowrap;
  font-size: 14px;
}

.barra-busca {
  float: right !important;
  border: none;
  outline: 0 !important;
  width: 150px !important;
  color: black;
}

.textarea {
  padding: 10px !important;
  min-height: 100px;
}

.input-select {
  width: auto;
  height: 35px;
  border: 1px solid #4a76b9;
  border-radius: 4px;
  background-color: white;
}

.input-theme {
  height: 35px !important;
  border: 1px solid #4a76b9 !important;
  border-radius: 4px !important;
  background-color: white !important;
  outline: none !important;
  color: black !important;
  box-shadow: none !important;
}

.input-theme.busca {
  width: 100%;
  height: 40px !important;
  border: 1px solid #4a76b9 !important;
  border-radius: 4px !important;
  background-color: white !important;
  outline: none !important;
  color: black !important;
  box-shadow: none !important;
}

.input-theme::placeholder {
  color: black !important;
}

input,
select {
  padding-left: 16px !important;
}

.react-select__placeholder {
  padding-left: 15px;
}

.btn-busca {
  height: 35px;
  width: 35px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.btn-busca:hover {
  background-color: #5b87ca;
}

@media screen and (min-width: 576px) {
  .btn-busca {
    height: 35px;
    width: 35px;
    background-color: #4a76b9;
    font-weight: bold;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 25px;
  }

  .btn-busca:hover {
    background-color: #5b87ca;
  }
}

.search-label {
  max-width: 250px;
}

@media screen and (max-width: 768px) {
  .search-label {
    width: 100% !important;
    max-width: 100%;
  }
}

.btn-primario-slim {
  height: 35px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

.btn-primario-slim:hover {
  background-color: #5b87ca;
}

@media screen and (min-width: 576px) {
  .btn-primario-slim {
    height: 35px;
    background-color: #4a76b9;
    font-weight: bold;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 25px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0;
  }

  .btn-primario-slim:hover {
    background-color: #5b87ca;
  }
}

.alert-antifraude,
.alert-antifraude:hover,
.alert-antifraude:visited {
  font-size: 14px;
  margin-left: 5px;
  color: red !important;
  text-decoration: none;
}

.link-clean,
.link-clean:hover,
.link-clean:visited {
  color: black;
}

.link-colored {
  background-color: #4a76b9;
  border-radius: 10px;
  width: 110px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.link-colored:hover {
  background-color: #5b87ca;
}

.border-colored {
  border: 1px solid white;
  border-radius: 10px;
  width: 110px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.border-colored:hover {
  background-color: #3f443a;
}

.f-22 {
  font-size: 22px;
}

.f-30 {
  font-size: 30px;
}

.logo-icone {
  width: 40px;
}

.font-color-secondary {
  color: #373e31 !important;
}

.btn-cadastrar {
  background-color: #ff6100;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-style: italic;
}

.btn-cadastrar:hover {
  background-color: #da5e11;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-style: italic;
  text-decoration: none;
}
