@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
.form-login-logo {
  max-width: 300px;
}

.form-login {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  width: 100%;
}

.form-login input,
.form-login input:-webkit-autofill,
.form-login input:-webkit-autofill:hover,
.form-login input:-webkit-autofill:focus,
.form-login select,
.form-login select:-webkit-autofill,
.form-login select:-webkit-autofill:hover,
.form-login select:-webkit-autofill:focus {
  background: white !important;
  border: 0;
  border-radius: 4px;
  height: 44px;
  padding: 0 15px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #4a76b9;
  -webkit-text-fill-color: black;
  -webkit-text-size-adjust: 1rem !important;
  font-size: 1rem !important;
  color: black;
}

.form-login input[type='file'] {
  color: black;
  background-color: white;
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
}

.form-login input[type='file']:before {
  display: none;
  font-style: normal;
  font-weight: normal;
}

.form-login label {
  color: white;
}

.form-login h5 {
  color: white;
}

.form-login-loja label {
  color: black;
}

.form-login-loja h5 {
  color: black;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv
  .sidenav---sidenav-nav---3tvij
  .sidenav---sidenav-navitem---uwIJ-:hover
  > .sidenav---sidenav-subnav---1EN61 {
  display: none;
}

.sidenav---sidenav---_2tBP {
  max-width: 64px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 500;
}

.sidenav---navicon---3gCRo {
  width: 100%;
}

.sidenav---sidenav---_2tBP {
  background-color: rgba(0, 0, 0, 0.9);
  transition: none;
}

.sidenav---sidenav-subnav---1EN61 {
  border-radius: 4px;
  border: none;
}

.sidenav---sidenav-navitem---uwIJ-:hover {
  background-color: rgba(0, 0, 0, 1);
  padding-left: 10px;
  transition: padding-left 0.2s;
}

.sidenav---navtext---1AE_f:hover {
  padding-left: 10px;
  transition: padding-left 0.2s;
}

.nav-font,
.nav-font:visited,
.nav-font:hover,
.nav-font:active,
.nav-font:focus,
.nav-font:active:hover {
  font: 16px 'Roboto', sans-serif;
  font-weight: 500;
  text-decoration: none !important;
  color: white;
}

.nav-toggle,
.sidenav---sidenav-toggle---1KRjR {
  margin: 10px 0px 10px 0px;
}

#logo-nav {
  display: flex;
  margin: 10px 0px 10px 0px;
  max-width: 110px;
  width: 100%;
}

#perfil {
  display: flex;
  flex-direction: column;
  cursor: default;
  height: auto;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
}

.nome-usuario {
  max-width: 100%;
  text-align: center;
  margin-bottom: 5px;
}
.nav-titulo {
  font-size: 16px;
  margin-top: 10px;
}

.nav-sub-titulo {
  font-size: 13px;
  background: none;
  border: none;
  color: white;
  text-align: start;
  margin-top: 10px;
}

.nav-sub-titulo:hover {
  font-size: 14px;
}

/* width */
nav::-webkit-scrollbar {
  width: 3px;
}

/* Track */
nav::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
nav::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
nav::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidenav---navtext---1AE_f,
.sidenav---navitem---9uL5T {
  width: 100% !important;
  height: 100% !important;
}

.link-unstyled,
.link-unstyled:visited,
.link-unstyled:hover,
.link-unstyled:active,
.link-unstyled:focus,
.link-unstyled:active:hover {
  font-style: inherit;
  color: inherit;
  background-color: inherit;
  font-size: inherit;
  text-decoration: inherit;
  font-feature-settings: inherit;
  font-variant: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-family: inherit;
  border-radius: inherit;
  border: inherit;
  outline: inherit;
  box-shadow: inherit;
  padding: inherit;
  vertical-align: inherit;
}

.main-painel {
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 70px;
  background-color: #eeeeee;
  overflow-y: auto;
}

.form-logo {
  max-width: 300px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
}

.form input,
.form input:-webkit-autofill,
.form input:-webkit-autofill:hover,
.form input:-webkit-autofill:focus,
.form select,
.form select:-webkit-autofill,
.form select:-webkit-autofill:hover,
.form select:-webkit-autofill:focus,
.form textarea,
.form textarea:-webkit-autofill,
.form textarea:-webkit-autofill:hover,
.form textarea:-webkit-autofill:focus {
  background: white !important;
  border: 0;
  border-radius: 4px;
  height: 44px;
  padding: 0 15px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #4a76b9;
  -webkit-text-fill-color: black;
  -webkit-text-size-adjust: 1rem !important;
  font-size: 1rem !important;
  color: black;
}

.form input[type='file'] {
  color: black;
  background-color: white;
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
}

.form input[type='file']:before {
  display: none;
  font-style: normal;
  font-weight: normal;
}

input::placeholder {
  color: green !important;
}


.card-dash {
  display: flex;
  border-radius: 4px;
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background: #4a76b9;
  align-items: center;
  width: 100%;
  color: white;
}

.card-dash-info {
  font-size: small;
  white-space: nowrap;
}

.card-dash-titulo {
  color: white;
  font-size: 18px;
  width: 50%;
  font-weight: 500;
  overflow-x: hidden;
}

.dash-table-titulo span {
  overflow-x: hidden;
}

.card-dash-subtitulo {
  color: white;
  font-size: 30px;
  text-align: end;
  width: 50%;
}
.card-dash-footer {
  width: 100%;
  background-color: white;
  height: 2px;
}

.dash-table {
  border-radius: 0px 0px 4px 4px;
}

.dash-col {
  border: none;
}

.dash-table-titulo {
  font-size: 14px;
  line-height: 50px;
  font-weight: 500;
  color: white;
  background-color: #2b3238;
  border-radius: 4px 4px 0px 0px;
  display: flex;
}

.dash-table-body {
  font-size: 14px;
}

.dash-table-header {
  font-size: 14px;
}

h4 {
  color: white;
}

.aviso {
  font-size: 12px;
  color: black;
}

label {
  font-size: 12px;
  color: black;
}

/* .react-select__value-container {
  height: 44px !important;
  padding: 0;
}

.react-select__single-value,
.react-select__placeholder {
  position: sticky !important;
}

.react-select__control.css-yk16xz-control {
  border-color: #4a76b9;
}

.react-select__input input {
  margin-bottom: 0 !important;
} */

.react-select__value-container {
  height: 50px;
}

.react-select__control.css-yk16xz-control {
  border-color: #4a76b9;
}

.lista-item {
  color: black;
  padding: 5px;
  margin: 0;
}

.lista {
  list-style: none;
  text-align: center;
  width: 100%;
}

.btn-adicionar {
  padding: 10px 15px 10px 15px;
  height: 44px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-remover {
  height: 44px;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: 0;
  margin-left: 5px;
  background-color: black;
  color: #4a76b9;
}

.btn-remover:hover {
  background-color: black;
}

#export-menu {
  display: block;
  height: auto;
  position: absolute;
  padding: 10px 0px 0px 0px;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  color: black;
  z-index: 10;
  top: 1;
  right: 0;
}

#export-menu button {
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  margin-bottom: 10px;
}

#export-menu button:hover {
  background-color: #cccccc;
}

#modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: rgba(0,0,0, .2);
}

#modal .conteudoModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 450px;
  height: 500px;
  padding: 20px;
  margin: 0;
  border-radius: 8px;
  background-color: white;
}

/* Estilo específico para o modal de histórico */
#modal .conteudoModal.historico-modal {
  width: 700px;
  height: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#modal .conteudoModal.historico-modal h5 {
  margin-bottom: 15px;
  text-align: center;
}

#modal .conteudoModal form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}

#modal .conteudoModal form label, .textoInativar {
  text-align: center;
  width: 65%;
  font-size: 1em;
  margin: 0 0 10px 0;
}

#modal .conteudoModal form label span, .textoInativar span {
  text-transform: capitalize;
}

#modal .conteudoModal form input {
  width: 65%;
  height: 35px;
  outline: none;
  border: .5px solid #bbb;
  border-radius: 6px;
  margin: 0 0 10px 0;
  padding: 5px;
  text-align: center;
}

#modal .conteudoModal form textarea {
  width: 65%;
  height: 100px;
  outline: none;
  border: .5px solid #bbb;
  border-radius: 6px;
  margin: 0 0 10px 0;
  padding: 5px;
}

#modal .conteudoModal form .form-group {
  width: 100%;
  margin-bottom: 15px;
}

#modal .conteudoModal form .botoes {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
}

#modal .conteudoModal form button {
  padding: 5px 20px;
  outline: 0;
  border: none;
  border-radius: 6px;
  background: #4A76B9;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  width: 120px;
}

#modal .conteudoModal form button[type='button'] {
  display: inline;
  background: #181818;
  margin: 0 0 0 10px;
}

.switch {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f00;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2.2px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: rgb(0, 161, 0);
}

input:focus+.slider {
  box-shadow: 0 0 1px rgb(0, 161, 0);
}

input:checked+.slider:before {
  transform: translateX(17px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.historico-lista {
  flex: 1 1;
  width: 100%;
  min-height: 450px;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
}

.historico-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.historico-item:last-child {
  border-bottom: none;
}

.historico-item p {
  margin-bottom: 5px;
}

.loading {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #666;
}

#modal .conteudoModal.historico-modal .botoes {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

#modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.2);
}

#modal .conteudoModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 450px;
  height: 200px;
  padding: 0;
  margin: 0;
  border-radius: 8px;
  background-color: white;
}

#modal .conteudoModal form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#modal .conteudoModal form label,
.textoInativar {
  text-align: center;
  width: 65%;
  font-size: 1em;
  margin: 0 0 10px 0;
}

#modal .conteudoModal form label span,
.textoInativar span {
  text-transform: capitalize;
}

#modal .conteudoModal form input {
  width: 65%;
  height: 35px;
  outline: none;
  border: 0.5px solid #bbb;
  border-radius: 6px;
  margin: 0 0 10px 0;
  padding: 5px;
  text-align: center;
}

#modal .conteudoModal form button {
  padding: 5px 20px;
  outline: 0;
  border: none;
  border-radius: 6px;
  background: #4a76b9;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  width: 120px;
}

#modal .conteudoModal form button[type='button'] {
  display: inline;
  background: #181818;
  margin: 0 0 0 10px;
}

.switch {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f00;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2.2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgb(0, 161, 0);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(0, 161, 0);
}

input:checked + .slider:before {
  transform: translateX(17px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.card-detalhe {
  width: 100%;
  height: 190px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.card-infos {
  display: flex;
  flex-direction: column;
  height: 100px;
  margin: auto;
  margin-top: 10px;
}

.card-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.card-links a {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.card-links a {
  color: #4a76b9;
}

.card-links a:hover {
  color: #5b87ca;
}

.card-imagem {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-imagem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.card-imagem video {
  width: 100%;
}

.card-midia {
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.card-midia-titulo {
  font-weight: bold;
}

.box-modulo {
  background-color: #dbdbdb;
  width: 480px;
  border-radius: 2rem;
  padding: 2rem;
  margin-bottom: 1rem;
  color: black;
}

.boxAula {
  padding: 1.5rem;
  background-color: #c9c9c9;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

#aula:hover {
  border-radius: 0.5rem;
  background-color: rgb(190, 190, 190);
}

.boxMaterial {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  background-color: #f7f7f7;
  border-radius: 0.5rem;
  margin: 0.5rem;
}

.boxMaterial:hover {
  border-radius: 0.5rem;
  background-color: #e6e6e6;
}

.box-pergunta {
  border-radius: 0.5rem;
  background-color: rgb(190, 190, 190);
  padding: 0.2rem;
}

.table {
  width: 100%;
  background-color: white;
  text-align: center;
}

.header-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  background-color: #373e31;
  font-size: 14px;
  width: 100%;
  padding-bottom: 4px;
}

.header-home-auto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  height: auto;
  background-color: #373e31;
  font-size: 14px;
  width: 100%;
  background-image: url('/imagens/barra-menu.png');
  background-size: cover;
  background-repeat: repeat-x;
  padding-bottom: 16px;
}

.header-home span,
.header-home a,
.header-home-auto span,
.header-home-auto a {
  color: white;
}

.header-home a:hover,
.header-home-auto a:hover {
  text-decoration: none;
  color: white;
}
.nav-brand {
  width: 70px;
}

.nav-brand-greater {
  width: 90px;
}
.nav-sair {
  background: none;
  border: none;
  color: white;
  text-align: center;
  align-self: flex-start;
}

@media (min-width: 1200px) {
  .nav-sair {
    background: none;
    border: none;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
  }
}

.header-acoes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.header-acoes button {
  background-color: transparent;
  color: white;
}

@media screen and (max-width: 768px) {
  .header-acoes {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
.smart {
  font-weight: 600;
  margin-right: 4px;
  font-size: 14px;
  font-family: 'Bahnschrift';
}

.nav-indicacao {
  min-width: 170px;
  padding: 4px 8px;
  /* height: 50px; */
  background-color: #6feffa;
  font-weight: bold;
  color: rgb(29, 59, 125) !important;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}

.nav-link:hover {
  padding-top: 3px;
}

@media (min-width: 1200px) {
  header .container {
    max-width: 1400px;
  }
}

.nav-link:hover {
  padding-top: 3px;
}

.nav-link {
  color: white !important;
}
.navbar .navbar-toggler-icon {
  color: white;
}

.nav-menu {
  color: white;
}

.nav-logo {
  max-width: 100px;
}
.navbar-toggler {
  width: 100%;
}

#video-modal .modal-content {
  background-color: transparent;
  border: none;
}

.footer {
  height: auto;
  background-color: rgb(18, 52, 116);
  font-size: 12px;
  width: 100%;
  color: white;
}
.footer-fixed {
  height: auto;
  background-color: rgb(18, 52, 116);
  font-size: 12px;
  width: 100%;
  position: fixed;
  bottom: 0;
  color: white;
}



.btn-primario3{
  background-color: rgb(201,81,61) !important;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

.modal-body {
  max-height: 80%;
  overflow-y: auto;
}

.modal-body-extended {
  max-height: 90%;
  overflow-y: auto;
}

.modal-header-extended {
  padding: 0.5rem;
}

.h-container {
  height: 85.77% !important;
}

.banner-home {
  max-width: 100%;
}

.banner2-home {
  width: 100%;
  height: auto;
}

.h-container {
  height: 87.77% !important;
}

@media only screen and (max-width: 992px) {
  .banner2-home {
    width: 100%;
  }
}

@media only screen and (max-width: 844px) {
  .h-container {
    height: 79.69% !important;
  }

  .banner2-home {
    width: auto;
    height: 240px;
  }
}

form.form.fundo-claro label {
  color: black !important;
}

form.form.loja {
  max-width: 100%;
}

.titulo-header {
  display: flex;
  align-items: center;
  text-align: left;
}

.btn-primario2{
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

.btn-primario2 .fa-facebook{
  padding-right: 6px;
}

#googlebtn button{
  width: 100%;
  box-shadow: 0 !important;
  border-radius: 4px !important;
}

@media screen and (max-width: 576px) {
  .titulo-header {
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
  }

  .banner2-home {
    width: auto;
    height: 200px;
  }
}

.h-container {
  height: 85.77% !important;
}

.teste {
  color: black;
}

.card-premio-nome {
  height: 50px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .card-premio-nome {
    height: 90px;
  }
}

.btn-resgatar {
  width: 130px;
  padding: 5px;
  height: 44px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-resgatar:hover {
  background-color: #5b87ca;
}

.input-busca {
  border: none;
  color: black;
  width: 150px;
}
.sticky{
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding: 10px 0px;
  z-index: 999;
}

@media (max-width: 767px) {
  .sticky-search{
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    padding: 10px 0px;
    z-index: 999;
    background-color: #fff;
  }
}

.input-busca::placeholder {
  color: grey !important;
  font-size: 12.5px;
}

@media screen and (min-width: 576px) {
  .input-busca {
    border: none;
    color: black;
    width: 250px;
  }

  .input-busca::placeholder {
    color: grey !important;
    font-size: 14px;
  }
}

.tag {
  background-color: #f1f1f1;
  color: #646363;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 8px;
  font-size: 14px;
}

.tag:hover {
  background-color: lightgray;
  cursor: pointer;
}

.tag.active {
  background-color: #4a76b9;
  color: white;
}

.tag.active:hover {
  background-color: #5785c9;
  cursor: pointer;
}

.busca-sem-resultados span,
.busca-sem-resultados i {
  font-size: 22px;
}

.busca-sem-resultados {
  border-radius: 4px;
  background-color: #f1f1f1;
  padding: 10px;
}

.busca-sem-resultados i {
  margin-right: 20px;
  margin-left: 20px;
}

.limpar-filtros button,
.limpar-filtros i {
  color: #5785c9 !important;
  font-size: small !important;
  cursor: pointer;
}

.limpar-filtros button:hover {
  -webkit-text-decoration: underline #5785c9 !important;
          text-decoration: underline #5785c9 !important;
}

.limpar-filtros i {
  margin-left: 4px;
}

.limpar-filtros {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.premio-titulo {
  font-size: 30px;
  font-weight: 500;
}

.premio-subtitulo {
  font-size: 18px;
  font-weight: 400;
}

.premio-descricao {
  font-size: 12px;
}

.premio-status {
  font-size: 12px;
  color: #fff;
  line-height: 18px;
  background: #6dbe14;
  text-transform: uppercase;
  padding: 3px 15px;
  font-weight: bold;
  border-radius: 999px;
}

.btn-add-qtde,
.btn-sub-qtde {
  width: 30px;
  height: 30px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-add-qtde:hover,
.btn-sub-qtde:hover {
  background-color: #5b87ca;
}

.slick-prev:before,
.slick-next:before {
  color: #4a76b9 !important;
  opacity: 100 !important;
}

.slick-prev {
  z-index: 1000 !important;
  left: -5px !important;
}

.slick-next {
  z-index: 1000 !important;
  right: -5px !important;
}

.slick-list {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.container {
  padding: 16px;
}

.container-header {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.profile-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.name {
  color: #4a76b9;
  font-weight: bold;
}

.info {
  font-size: 14px;
  color: #aaa;
}

.container-content {
  padding: 16px;
}

.star-rating {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}

.star-wrapper {
  display: flex;
  /* gap: 4px; */
}

.star-wrapper input[type='radio'] {
  display: none;
}
.star-wrapper i {
  font-size: 20px;
  padding: 0.125rem;
}

.img-btn {
  border: none;
}

.img-list-wrapper {
  display: flex;
}

.img-list-wrapper img {
  width: 50px;
  height: 50px;
}

.rate-wrapper {
  margin: 1rem;
  /* padding: 2rem; */
}

.form-wrapper {
  margin-top: 1rem;
}

.form-wrapper textarea {
  width: 100%;
  padding: 0.5rem;

  border: 1px solid #4a76b9;
  border-radius: 0.25rem;
}

.btn-file {
  background: white !important;
  border: 0;
  border-radius: 4px;
  height: 44px;
  padding: 0 15px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #4a76b9;
  -webkit-text-fill-color: black;
  -webkit-text-size-adjust: 1rem !important;
  font-size: 1rem !important;
  color: black;
  width: 100%;
}

.btn-wrapper {
  display: flex;

  justify-content: end;
}

.icon-wrapper {
  color: #4a76b9;
}

.btn-atualizar {
  padding: 5px;
  width: 200px;
  height: 44px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-atualizar:hover {
  background-color: #5b87ca;
}

.tabela-header-claro {
  text-align: center !important;
}

.tabela-body-claro {
  text-align: center !important;
}

.pagination.react-bootstrap-table-page-btns-ul li.active a {
  color: white !important;
  background-color: #4a76b9 !important;
  border: none;
}

.pagination.react-bootstrap-table-page-btns-ul li a {
  color: #4a76b9 !important;
  background-color: white;
  border: none;
}

.dropdown-item a {
  color: black;
}

#pageDropDown {
  color: white !important;
  background-color: #4a76b9 !important;
  border: none;
  box-shadow: none;
}

div.col-md-6.col-xs-6.col-sm-6.col-lg-6,
.react-bootstrap-table-pagination-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.page-link:focus {
  box-shadow: none !important;
}

.sort-asc,
.sort-desc {
  color: black;
}

.react-bootstrap-table {
  overflow-y: auto;
}

.tabela-width {
  max-width: 800px;
}

.contato-mensagem {
  min-height: 100px;
}

.btn-enviar {
  width: 130px;
  padding: 5px;
  height: 44px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-enviar:hover {
  background-color: #5b87ca;
}
#codigoPromocional:disabled {
  border: .5px solid #ccc;
}
.mensagemCodigoPromocional {
  color: black;
}

.card-cartao {
  display: flex;
  border-radius: 4px;
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background: #4a76b9;
  align-items: center;
  width: 100%;
  min-height: 115px;
  height: 100%;
}

.card-cartao-titulo {
  color: white;
  font-size: 18px;
  width: 35%;
  font-weight: 500;
  overflow-x: hidden;
  text-align: center;
}

.cartao-table-titulo span {
  overflow-x: hidden;
}

.card-cartao-subtitulo {
  color: white;
  font-size: 22px;
  text-align: end;
  width: 65%;
}
.card-cartao-footer {
  width: 100%;
  background-color: white;
  height: 1px;
  color: white;
}

@media screen and (max-width: 991px) {
  .card-cartao {
    font-size: 22px;
    display: flex;
    border-radius: 4px;
    height: 100px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    background: #4a76b9;
    align-items: center;
    width: 100%;
    min-height: 252px;
    height: 100%;
  }

  .card-cartao-titulo {
    color: white;
    font-size: 22px;
    width: 35%;
    font-weight: 500;
    overflow-x: hidden;
    text-align: center;
  }

  .card-cartao-subtitulo {
    color: white;
    font-size: 50px;
    text-align: end;
    width: 65%;
  }
  .card-cartao-footer {
    width: 100%;
    background-color: white;
    height: 1px;
    color: white;
  }
}

.f-24 {
  font-size: 24px;
}

.card-sem-cartao {
  font-size: 18px;
  display: flex;
  border-radius: 4px;
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background: #4a76b9;
  align-items: center;
  width: 100%;
  min-height: 115px;
  height: 100%;
  justify-content: center;
  text-align: center;
  color: white;
}

.card-sem-cartao-footer {
  width: 100%;
  background-color: white;
  height: 1px;
  color: white;
}

@media screen and (max-width: 991px) {
  .card-sem-cartao {
    font-size: 22px;
    display: flex;
    border-radius: 4px;
    height: 100px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    background: #4a76b9;
    align-items: center;
    width: 100%;
    min-height: 252px;
    height: 100%;
    justify-content: center;
    color: white;
    text-align: center;
  }

  .card-sem-cartao-footer {
    width: 100%;
    background-color: white;
    height: 1px;
    color: white;
  }
}

.cartao-info-verde {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 115px;
  width: 100%;
  background-color: #1e7e34;
}

.cartao-info-vermelho {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 115px;
  width: 100%;
  background-color: #ff0000;
}

.cartao-info-preto {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 115px;
  width: 100%;
  background-color: #000000;
}

.cartao-info-marrom {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 115px;
  width: 100%;
  background-color: #86724c;
}

.card-infos {
  display: flex;
  flex-direction: column;
  height: 100px;
  margin: auto;
  margin-top: 10px;
}

.card-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.card-links a {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.card-links a {
  color: #4a76b9;
}

.card-links a:hover {
  color: #5b87ca;
}

.card-imagem {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-imagem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.card-imagem video {
  width: 100%;
}

.card-midia {
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.card-midia-titulo {
  font-weight: bold;
}

.btn-atualizar {
  padding: 5px;
  width: 200px;
  height: 44px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-atualizar:hover {
  background-color: #5b87ca;
}

.btn-atualizar {
  padding: 5px;
  width: 200px;
  height: 44px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-atualizar:hover {
  background-color: #5b87ca;
}

.box-curso {
  width: 280px;
  height: 370px;
  margin-top: 1.5rem;
  border-radius: 0.8rem;
  position: relative;
  cursor: pointer;
}

.capa {
  width: 100%;
  height: 170px;
  border-radius: 0.8rem 0.8rem 0rem 0rem;
}

@media screen and (min-width: 320px) {
  .capa {
    width: 200px !important;
    height: 170px !important;
    border-radius: 0.8rem 0.8rem 0rem 0rem;
  }
}

@media screen and (min-width: 600px) {
  .capa {
    width: 100% !important;
    height: 170px !important;
    border-radius: 0.8rem 0.8rem 0rem 0rem;
  }
}

.box-buttons {
  width: 100%;
  bottom: 0;
  padding-bottom: 0.8rem;
}

.tag-curso {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.8rem;
  padding: 3px;
  font-weight: 700;
  font-size: 12px;
  border-radius: 0.3rem;
  background-color: rgb(69, 219, 0);
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .card-premio-nome {
    height: 90px;
  }
}

.btn-resgatar {
  width: 130px;
  padding: 5px;
  height: 44px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-resgatar:hover {
  background-color: #5b87ca;
}

.input-busca {
  border: none;
  color: black;
  width: 150px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding: 10px 0px;
  z-index: 999;
}

@media (max-width: 767px) {
  .sticky-search {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    padding: 10px 0px;
    z-index: 999;
    background-color: #fff;
  }
}

.input-busca::placeholder {
  color: grey !important;
  font-size: 12.5px;
}

@media screen and (min-width: 576px) {
  .input-busca {
    border: none;
    color: black;
    width: 250px;
  }

  .input-busca::placeholder {
    color: grey !important;
    font-size: 14px;
  }
}

.busca-sem-resultados span,
.busca-sem-resultados i {
  font-size: 22px;
}

.busca-sem-resultados {
  border-radius: 4px;
  background-color: #f1f1f1;
  padding: 10px;
}

.busca-sem-resultados i {
  margin-right: 20px;
  margin-left: 20px;
}

.limpar-filtros button,
.limpar-filtros i {
  color: #5785c9 !important;
  font-size: small !important;
  cursor: pointer;
}

.limpar-filtros button:hover {
  -webkit-text-decoration: underline #5785c9 !important;
          text-decoration: underline #5785c9 !important;
}

.limpar-filtros i {
  margin-left: 4px;
}

.limpar-filtros {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .btn-acessar {
    height: 42px;
    background-color: #28a745;
    font-weight: bold;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
}

.btn-acessar:hover {
  background-color: rgb(40, 167, 69, 0.9);
}

.box-curso {
  width: 280px;
  height: 300px;
  margin-top: 1.5rem;
  border-radius: 0.8rem;
  position: relative;
  margin: 1rem 1rem;
  cursor: pointer;
}

.capa {
  width: 100%;
  height: 170px;
  border-radius: 0.8rem 0.8rem 0rem 0rem;
}

.box-buttons {
  width: 100%;
  bottom: 0;
  padding-bottom: 0.8rem;
}

.btn-inscreva-se {
  white-space: nowrap;

  min-height: 42px;
  background-color: rgb(56, 63, 49);
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  width: 200px;
}

@media (max-width: 767px) {
  .btn-inscreva-se {
    white-space: nowrap;
    min-height: 42px;
    background-color: rgb(56, 63, 49);
    font-weight: bold;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
}

.btn-inscreva-se:hover {
  white-space: nowrap;

  background-color: rgb(56, 63, 49, 0.9);
}

.btn-asssistir {
  height: 42px;
  background-color: #28a745;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  width: 200px;
}

@media (max-width: 767px) {
  .btn-asssistir {
    height: 42px;
    background-color: #28a745;
    font-weight: bold;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
}

.btn-asssistir:hover {
  background-color: rgb(40, 167, 69, 0.9);
}

.curso-box {
  background-color: rgb(243, 243, 243);
  border-radius: 0.5rem;
}

.avalicao-box {
  background-color: #373e31;
  border-radius: 0.5rem;

  color: #fff;
}

#video-modal div {
  font-family: 'Inter', Helvetica, sans-serif;
  height: 100%;
  width: 100%;
  background: white;
}

#video-modal .modal-header {
  border: none;
  margin: 0 0 -50px 0;
}

#video-modal .modal-header button {
  z-index: 9999;
}

#video-modal .modal-body {
  padding: 2% 5%;
  border-radius: 0 0 8px 6px;
}

#video-modal .video-yt {
  height: 100%;
  width: 100%;
}

.tutorial-texto {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tutorial-texto h2 { color: #4A76B9 }
.tutorial-texto h3 { font-size: 1.8em; font-weight: bold; color: #0cc2cb; margin: 0 0 30px 0 }

@media screen and (max-width: 800px) {
  #video-modal .modal-header {
    border: none;
    border-bottom: .3px solid white;
    margin: 0;
  }

  #video-modal .modal-body {
    padding: 0 0 0 60px;
    margin: -40px 0 0 0;
  }
}

.btn-primario {
  padding: 0.5rem 1rem;
}

.menu-box {
}

.h-container {
  height: 85.77% !important;
}

.form-login-logo {
  max-width: 300px;
}

.form-login {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  width: 100%;
}

.form-login input,
.form-login input:-webkit-autofill,
.form-login input:-webkit-autofill:hover,
.form-login input:-webkit-autofill:focus,
.form-login select,
.form-login select:-webkit-autofill,
.form-login select:-webkit-autofill:hover,
.form-login select:-webkit-autofill:focus {
  background: white !important;
  border: 0;
  border-radius: 4px;
  height: 44px;
  padding: 0 15px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #4a76b9;
  -webkit-text-fill-color: black;
  -webkit-text-size-adjust: 1rem !important;
  font-size: 1rem !important;
  color: black;
}

.form-login input[type='file'] {
  color: black;
  background-color: white;
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
}

.form-login input[type='file']:before {
  display: none;
  font-style: normal;
  font-weight: normal;
}

.form-login label {
  color: white;
}

.form-login h5 {
  color: white;
}

.form-login-loja label {
  color: black;
}

.form-login-loja h5 {
  color: black;
}

.main-painel {
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 70px;
  background-color: #eeeeee;
  overflow-y: auto;
}

.form-logo {
  max-width: 300px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
}

.form input,
.form input:-webkit-autofill,
.form input:-webkit-autofill:hover,
.form input:-webkit-autofill:focus,
.form select,
.form select:-webkit-autofill,
.form select:-webkit-autofill:hover,
.form select:-webkit-autofill:focus,
.form textarea,
.form textarea:-webkit-autofill,
.form textarea:-webkit-autofill:hover,
.form textarea:-webkit-autofill:focus {
  background: white !important;
  border: 0;
  border-radius: 4px;
  height: 44px;
  padding: 0 15px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #4a76b9;
  -webkit-text-fill-color: black;
  -webkit-text-size-adjust: 1rem !important;
  font-size: 1rem !important;
  color: black;
}

.form input[type='file'] {
  color: black;
  background-color: white;
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
}

.form input[type='file']:before {
  display: none;
  font-style: normal;
  font-weight: normal;
}

input::placeholder {
  color: green !important;
}


.card-dash {
  display: flex;
  border-radius: 4px;
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background: #4a76b9;
  align-items: center;
  width: 100%;
  color: white;
}

.card-dash-info {
  font-size: small;
  white-space: nowrap;
}

.card-dash-titulo {
  color: white;
  font-size: 18px;
  width: 50%;
  font-weight: 500;
  overflow-x: hidden;
}

.dash-table-titulo span {
  overflow-x: hidden;
}

.card-dash-subtitulo {
  color: white;
  font-size: 30px;
  text-align: end;
  width: 50%;
}

.card-dash-footer {
  width: 100%;
  background-color: white;
  height: 2px;
}

.dash-table {
  border-radius: 0px 0px 4px 4px;
}

.dash-col {
  border: none;
}

.dash-table-titulo {
  font-size: 14px;
  line-height: 50px;
  font-weight: 500;
  color: white;
  background-color: #2b3238;
  border-radius: 4px 4px 0px 0px;
  display: flex;
}

.dash-table-body {
  font-size: 14px;
}

.dash-table-header {
  font-size: 14px;
}

h4 {
  color: white;
}

.table-bottom-bar {
  height: 4px;
  background-color: #007bff;
  margin-top: 1px;
  border-radius: 0 0 4px 4px;
}

.dashboard-datatable .search-bar {
  height: 38px;
}

.dashboard-datatable .btn-primario-slim {
  height: 38px;
  min-width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.dashboard-datatable .datatable-controls {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

/* Espaço entre o select e o texto de resultados */
.dashboard-datatable .col-md-6 span {
  margin-left: 15px !important;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1380px !important;
  }
}

.capitalize {
  text-transform: lowercase !important;
  -webkit-text-transform: lowercase !important;
}

.capitalize::first-line {
  text-transform: capitalize !important;
  -webkit-text-transform: capitalize !important;
}

/* Fix Webkit ::first-line issues */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .capitalize::first-line {
    font-feature-settings: "smcp";
    font-variant: small-caps;
  }
  .capitalize::first-letter {
  }
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*:focus {
  outline: 0 !important;
}

html,
body,
#root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialased;
}

body,
input,
button {
  font:
    1rem 'Roboto',
    sans-serif;
}

button {
  cursor: pointer;
}

.Toastify__toast--success {
  background-color: #4a76b9;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--error {
  background-color: #4a76b9;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--info {
  background-color: #4a76b9;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--warning {
  background-color: #4a76b9;
  border-radius: 4px;
  text-align: center;
}

.f-16 {
  font-size: 16px;
}

.f-14 {
  font-size: 14px;
}

.bg-black {
  background-color: black !important;
}

.f-black {
  color: black;
}

.f-white {
  color: white;
}

.f-blue {
  color: #4a76b9;
}

.btn-primario {
  margin: 5px 0 0;
  height: 44px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-primario:hover {
  background-color: #5b87ca;
}

.btn-vermelho {
  margin: 5px 0 0;
  height: 44px;
  background-color: #dc3545;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-vermelho:hover {
  background-color: #c82333;
}

.btn-secundario {
  margin: 5px 0 0;
  height: 44px;
  background-color: black;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.btn-secundario:hover {
  background-color: black;
  color: white;
}

.f-10 {
  font-size: 10px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-white {
  color: white !important;
}

.f-green {
  color: green;
}

.f-red {
  color: red;
}

.hr-loja {
  width: 100%;
  border-top: 3px solid #4a76b9;
}

.hr-curso {
  width: 100%;
  border-top: 3px solid #a5a5a5;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, '.') '. ';
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, '.') ' ';
}

.row:before,
.row:after {
  display: none !important;
}

.caret-4-desc:after {
  content: '\2193';
}
.caret-4-desc:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 0.4;
}

.caret-4-asc:after {
  content: '\2193';
  opacity: 0.4;
}
.caret-4-asc:before {
  margin-left: 3.5px;
  content: '\2191';
}

ul.pagination.react-bootstrap-table-page-btns-ul {
  margin-top: 1rem;
}
.btn-whats {
  margin: 5px 0 0;
  height: 44px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  padding: 12px;
  cursor: pointer;
  text-decoration: none !important;
}
.btn-limpo {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

div.ril-toolbar.ril__toolbar {
  background-color: unset;
}

.table-dark td {
  border-color: #ebeced !important;
  background-color: white;
  color: black;
}

.table-dark tfoot {
  background-color: white;
  color: black;
}

.table-dark thead,
.table-dark th {
  border-color: #4a76b9 !important;
  border-bottom: none;
}

.table-dark {
  background-color: #4a76b9 !important;
  white-space: nowrap;
  font-size: 14px;
}

.barra-busca {
  float: right !important;
  border: none;
  outline: 0 !important;
  width: 150px !important;
  color: black;
}

.textarea {
  padding: 10px !important;
  min-height: 100px;
}

.input-select {
  width: auto;
  height: 35px;
  border: 1px solid #4a76b9;
  border-radius: 4px;
  background-color: white;
}

.input-theme {
  height: 35px !important;
  border: 1px solid #4a76b9 !important;
  border-radius: 4px !important;
  background-color: white !important;
  outline: none !important;
  color: black !important;
  box-shadow: none !important;
}

.input-theme.busca {
  width: 100%;
  height: 40px !important;
  border: 1px solid #4a76b9 !important;
  border-radius: 4px !important;
  background-color: white !important;
  outline: none !important;
  color: black !important;
  box-shadow: none !important;
}

.input-theme::placeholder {
  color: black !important;
}

input,
select {
  padding-left: 16px !important;
}

.react-select__placeholder {
  padding-left: 15px;
}

.btn-busca {
  height: 35px;
  width: 35px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.btn-busca:hover {
  background-color: #5b87ca;
}

@media screen and (min-width: 576px) {
  .btn-busca {
    height: 35px;
    width: 35px;
    background-color: #4a76b9;
    font-weight: bold;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 25px;
  }

  .btn-busca:hover {
    background-color: #5b87ca;
  }
}

.search-label {
  max-width: 250px;
}

@media screen and (max-width: 768px) {
  .search-label {
    width: 100% !important;
    max-width: 100%;
  }
}

.btn-primario-slim {
  height: 35px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

.btn-primario-slim:hover {
  background-color: #5b87ca;
}

@media screen and (min-width: 576px) {
  .btn-primario-slim {
    height: 35px;
    background-color: #4a76b9;
    font-weight: bold;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 25px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0;
  }

  .btn-primario-slim:hover {
    background-color: #5b87ca;
  }
}

.alert-antifraude,
.alert-antifraude:hover,
.alert-antifraude:visited {
  font-size: 14px;
  margin-left: 5px;
  color: red !important;
  text-decoration: none;
}

.link-clean,
.link-clean:hover,
.link-clean:visited {
  color: black;
}

.link-colored {
  background-color: #4a76b9;
  border-radius: 10px;
  width: 110px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.link-colored:hover {
  background-color: #5b87ca;
}

.border-colored {
  border: 1px solid white;
  border-radius: 10px;
  width: 110px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.border-colored:hover {
  background-color: #3f443a;
}

.f-22 {
  font-size: 22px;
}

.f-30 {
  font-size: 30px;
}

.logo-icone {
  width: 40px;
}

.font-color-secondary {
  color: #373e31 !important;
}

.btn-cadastrar {
  background-color: #ff6100;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-style: italic;
}

.btn-cadastrar:hover {
  background-color: #da5e11;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-style: italic;
  text-decoration: none;
}

.iiz {
  margin: 0;
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: zoom-in;
}

.iiz__img {
  max-width: 100%;
  height: auto;
  display: block;
  pointer-events: none;
}

.iiz__zoom-img {
  width: auto !important;
  max-width: none !important;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  display: block;
}

.iiz__zoom-img--visible {
  visibility: visible;
  opacity: 1;
  cursor: zoom-out;
}

.iiz__zoom-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.iiz__btn {
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  padding: 0;
  position: absolute;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
          appearance: none;
}

.iiz__btn:before {
  content: ' ';
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.iiz__hint {
  bottom: 10px;
  right: 10px;
  pointer-events: none;
}

.iiz__hint:before {
  content: ' ';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='%23000222'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}

.iiz__close {
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
}

.iiz__close--visible {
  visibility: visible;
  opacity: 1;
}

.iiz__close::before {
  content: ' ';
  width: 29px;
  height: 29px;
  background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
  background-size: 100% 1px, 1px 100%;
  transform: rotate(45deg);
}

