.card-dash {
  display: flex;
  border-radius: 4px;
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background: #4a76b9;
  align-items: center;
  width: 100%;
  color: white;
}

.card-dash-info {
  font-size: small;
  white-space: nowrap;
}

.card-dash-titulo {
  color: white;
  font-size: 18px;
  width: 50%;
  font-weight: 500;
  overflow-x: hidden;
}

.dash-table-titulo span {
  overflow-x: hidden;
}

.card-dash-subtitulo {
  color: white;
  font-size: 30px;
  text-align: end;
  width: 50%;
}

.card-dash-footer {
  width: 100%;
  background-color: white;
  height: 2px;
}

.dash-table {
  border-radius: 0px 0px 4px 4px;
}

.dash-col {
  border: none;
}

.dash-table-titulo {
  font-size: 14px;
  line-height: 50px;
  font-weight: 500;
  color: white;
  background-color: #2b3238;
  border-radius: 4px 4px 0px 0px;
  display: flex;
}

.dash-table-body {
  font-size: 14px;
}

.dash-table-header {
  font-size: 14px;
}

h4 {
  color: white;
}

.table-bottom-bar {
  height: 4px;
  background-color: #007bff;
  margin-top: 1px;
  border-radius: 0 0 4px 4px;
}

.dashboard-datatable .search-bar {
  height: 38px;
}

.dashboard-datatable .btn-primario-slim {
  height: 38px;
  min-width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.dashboard-datatable .datatable-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Espaço entre o select e o texto de resultados */
.dashboard-datatable .col-md-6 span {
  margin-left: 15px !important;
}
